import { Injectable, signal } from '@angular/core';
import { Observable, firstValueFrom } from 'rxjs';
import { isNullOrUndefined, throwError } from '../shared/util';
import { Client } from '../entities/household';
import { HouseholdService } from './household.service';
import { HttpClient } from '@angular/common/http';
import { InsurancePreassessmentQuestion } from '../entities/insurance-preassessment-question';
import { InsurancePreassessmentQuestions } from '../enums/insurance-preassessment-question';
import { JourneyClient } from '../entities/journey-client';
import { JourneyService } from './journey.service';
import { MasterDataService } from './master-data.service';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class InsurancePreassessmentQuestionService {

  constructor(
    private http: HttpClient,
    private householdService: HouseholdService,
    private journeyService: JourneyService
  ) { }

  insurancePreassessmentQuestions = signal<InsurancePreassessmentQuestion[]>([]);
  private readonly booleanTrueString = "true";
  private readonly booleanFalseString = "false";

  getInsurancePreassessmentQuestions(): Observable<InsurancePreassessmentQuestion[]> {
    const journeyID = this.journeyService.getNonNullableJourney().journeyID;
    return this.http.get<InsurancePreassessmentQuestion[]>(`/api/insurancepreassessmentquestions/${journeyID}/latest`);
  }

  saveInsurancePreassessmentQuestions(insurancePreassessmentQuestions: InsurancePreassessmentQuestion[]): Observable<InsurancePreassessmentQuestion[]> {
    return this.http.post<InsurancePreassessmentQuestion[]>("/api/insurancepreassessmentquestions/", insurancePreassessmentQuestions);
  }

  async updateInsurancePreassessmentQuestion(...insurancePreassessmentQuestions: InsurancePreassessmentQuestion[]): Promise<void> {
    this.insurancePreassessmentQuestions
      .update(x => [
        ...x.filter(y => !insurancePreassessmentQuestions.find(z => z.journeyClientID === y.journeyClientID && z.questionID === y.questionID)),
        ...insurancePreassessmentQuestions
      ]);

    await firstValueFrom(this.saveInsurancePreassessmentQuestions(insurancePreassessmentQuestions));
  }

  async addInsurancePreassessmentQuestion(insurancePreassessmentQuestion: InsurancePreassessmentQuestion): Promise<void> {
    this.insurancePreassessmentQuestions.update(x => [...x, insurancePreassessmentQuestion]);
    await firstValueFrom(this.saveInsurancePreassessmentQuestions([insurancePreassessmentQuestion]));
  }

  async refresh(): Promise<void> {
    this.insurancePreassessmentQuestions.set(await firstValueFrom(this.getInsurancePreassessmentQuestions()));

    if (!this.householdService.household) return;

    await this.refreshClient(this.householdService.household.client);

    if (this.householdService.household.spouse) {
      await this.refreshClient(this.householdService.household.spouse);
    }
  }

  getInsurancePreassessmentQuestionValue(questionID: number, client: JourneyClient, type: "number"): null | undefined | number
  getInsurancePreassessmentQuestionValue(questionID: number, client: JourneyClient, type: "boolean"): null | undefined | boolean
  getInsurancePreassessmentQuestionValue(questionID: number, client: JourneyClient, type: "string"): null | undefined | string
  getInsurancePreassessmentQuestionValue(questionID: number, client: JourneyClient, type: "number" | "boolean" | "string"): string | null | undefined | number | boolean {
    const question = this.insurancePreassessmentQuestions().find(x => x.questionID === questionID && x.journeyClientID === client.journeyClientID);
    const rawValue = question?.value;

    switch (type) {
      case "string":
        return rawValue;
      case "boolean":
        return this.stringToBoolean(rawValue);
      case "number":
        return this.stringToNumber(rawValue)
      default:
        return throwError(`The value of type is invalid: ${rawValue}`);
    }
  }

  getInsurancePreassessmentQuestionComment(questionID: number, client: JourneyClient): string | null | undefined {
    const question = this.insurancePreassessmentQuestions().find(x => x.questionID === questionID && x.journeyClientID === client.journeyClientID);
    return question?.comment ?? "";
  }

  createJourneyExcludedAdviceArea(questionID: number, journeyClientID?: string, value?: string | null): InsurancePreassessmentQuestion {
    return {
      journeyID: this.journeyService.getNonNullableJourney().journeyID,
      journeyClientID,
      created: moment().utc().toDate(),
      lastModified: moment().utc().toDate(),
      questionID,
      value
    };
  }

  async loadInsurancePreassessmentQuestions(): Promise<void> {
    this.insurancePreassessmentQuestions.set(await firstValueFrom(this.getInsurancePreassessmentQuestions()));
  }

  private async refreshClient(client: Client): Promise<void> {
    await this.updateInsurancePreassessmentQuestion(this.createJourneyExcludedAdviceArea(InsurancePreassessmentQuestions.Height, client.clientID, this.numberToString(client.height)));
    await this.updateInsurancePreassessmentQuestion(this.createJourneyExcludedAdviceArea(InsurancePreassessmentQuestions.Weight, client.clientID, this.numberToString(client.weight)));
    const haveYouGainedLostMoreThanFiveKG = this.createJourneyExcludedAdviceArea(InsurancePreassessmentQuestions.HaveYouGainedLostMoreThanFiveKG, client.clientID, this.booleanToString(client.wasWeightLostLast12Months, false));
    haveYouGainedLostMoreThanFiveKG.comment = client.weightLossLast12MonthsDetails;
    await this.updateInsurancePreassessmentQuestion(haveYouGainedLostMoreThanFiveKG);

    const haveYouSmokedAnySubstancesLastTwelveMonths = this.createJourneyExcludedAdviceArea(InsurancePreassessmentQuestions.HaveYouSmokedAnySubstancesLastTwelveMonths, client.clientID, this.booleanToString(client.hasSmokedLast12Months, false));
    haveYouSmokedAnySubstancesLastTwelveMonths.comment = client.smokedLast12MonthsDetails;
    await this.updateInsurancePreassessmentQuestion(haveYouSmokedAnySubstancesLastTwelveMonths);

    await this.updateInsurancePreassessmentQuestion(this.createJourneyExcludedAdviceArea(InsurancePreassessmentQuestions.HowManyStandardDrinksPerWeek, client.clientID, this.numberToString(client.standardDrinksPerWeek)));
    if (client.health) {
      await this.updateInsurancePreassessmentQuestion(this.createJourneyExcludedAdviceArea(InsurancePreassessmentQuestions.Health, client.clientID,
        this.numberToString(MasterDataService.getIdForName(client.health, MasterDataService.getHealthTypes()))));
    } else {
      await this.updateInsurancePreassessmentQuestion(this.createJourneyExcludedAdviceArea(InsurancePreassessmentQuestions.Health, client.clientID, this.numberToString(MasterDataService.getHealthTypes()[0].iD)));
    }
  }

  stringToBoolean(value: string | null | undefined): boolean | null {
    if (isNullOrUndefined(value)) {
      return null;
    }

    return value === this.booleanTrueString;
  }

  booleanToString(value: boolean | null | undefined, convertNullUndefinedToFalse: boolean): string | null {
    if (isNullOrUndefined(value) && !convertNullUndefinedToFalse) {
      return null;
    }
    return value ? this.booleanTrueString : this.booleanFalseString;
  }

  // Method can't be static because it must be callable from DI.
  // eslint-disable-next-line class-methods-use-this
  numberToString(value: number | null | undefined): string | null {
    if (isNullOrUndefined(value)) {
      return null;
    }

    return String(value);
  }

  // Method can't be static because it must be callable from DI.
  // eslint-disable-next-line class-methods-use-this
  stringToNumber(value: string | null | undefined): number | null {
    if (isNullOrUndefined(value)) {
      return null;
    }

    return Number(value);
  }
}