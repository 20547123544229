export enum InsurancePreassessmentQuestions {
    Health = 1,
    AreYouCurrentlyPregnant = 2,
    DueDateAndAnyAbnormalTestResults = 3,
    Height = 4,
    Weight = 5,
    BMI = 6,
    HaveYouGainedLostMoreThanFiveKG = 7,
    HaveYouGainedLostMoreThanFiveKGComment = 8,
    HaveYouSmokedAnySubstancesLastTwelveMonths = 9,
    HaveYouSmokedAnySubstancesLastTwelveMonthsComment = 10,
    HowManyStandardDrinksPerWeek = 11,
    AreYouCurrentlyOnAnyMedication = 12,
    WhatTypeDosageHowLong = 13,
    HaveYouHadAnySurgeries = 14,
    DoctorVisitResults = 15,
    ImmediateFamilySignificantMedicalCondition = 16,
    RelationshipNameMedicalCondition = 17,
    AnyMedicalCondition = 18,
    MedicalConditionDetailsDiagnosed = 19,
    ClaimForPersonalInsurance = 20,
    ClaimForPersonalInsuranceComment = 21,
    TakenAnyDrugsInFiveYear = 22,
    MedicationDosageRequired = 23,
    DeclinedPostponedInsurance = 24,
    DeclinedPostponedInsuranceComment = 25,
    ProfessionalTradeQualifications = 26,
    ProfessionalTradeQualificationsComment = 27,
    PercentageWorkOfficeAdminBased = 28,
    PercentageWorkRequiresManualLabour = 29,
    PercentageWorkWhichDriving = 30,
    WorkUnderground = 31,
    WorkUndergroundComment = 32,
    JobLength = 33,
    JobLengthComment = 34,
    ParticipateContactCompetitiveSports = 35,
    ParticipateContactCompetitiveSportsComment = 36
}
